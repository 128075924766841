<template>
  <div>
    <div class="i-layout-page-header header-title">
      <span class="ivu-page-header-title mr20">{{ $route.meta.title }}</span>
      <div>
        <div style="float: right">
          <Button class="bnt" type="primary" @click="handleSave">保存</Button>
        </div>
      </div>
    </div>

    <Card :bordered="false" dis-hover class="ivu-mt">
      <div class="box-wrapper">
        <div class="left-wrapper" v-if="!$route.params.id && groupAll.length">
          <Menu theme="light" :active-name="sortName" width="auto">
            <MenuGroup>
              <MenuItem :name="item.config_name" class="menu-item" v-for="(item, index) in groupAll" :key="index" @click.native="edits(item)">
                {{ item.name }}
              </MenuItem>
              <MenuItem name="guide" class="menu-item" @click.native="edits(2)"> 开屏广告 </MenuItem>
            </MenuGroup>
          </Menu>
        </div>

        <div v-if="name != 'sign_day_num' && a != 1 && guide != 2" :class="name != 'admin_login_slide' ? 'content' : 'contents'">
          <div class="right-box">
            <div class="hot_imgs">
              <div class="title" v-if="name == 'admin_login_slide'">幻灯片设置</div>
              <div class="title" v-else>轮播图设置</div>
              <div class="title-text">建议尺寸：690 * 240px，拖拽图片可调整图片顺序哦，最多添加五张</div>
              <div class="list-box">
                <draggable
                  v-if="name == 'admin_login_slide'"
                  class="dragArea list-group Bbox"
                  :list="tabList.list"
                  group="peoples"
                  handle=".move-icon">
                  <div class="items" v-for="(item, index) in tabList.list" :key="index">
                    <div class="move-icon">
                      <span class="iconfont icondrag2"></span>
                    </div>
                    <div class="img-box" @click="handleModalPicTap(index)">
                      <img :src="item.slide" alt="" v-if="item.slide" />
                      <div class="upload-box" v-else>
                        <Icon type="ios-camera-outline" size="36" />
                      </div>
                      <div class="delect-btn" @click.stop="handleBindDelete(item, index)">
                        <Icon type="md-close-circle" size="26" />
                      </div>
                    </div>
                    <div class="info"></div>
                  </div>
                </draggable>
                <draggable v-else class="dragArea list-group" :list="tabList.list" group="peoples" handle=".move-icon">
                  <div class="item" v-for="(item, index) in tabList.list" :key="index">
                    <div class="move-icon">
                      <span class="iconfont icondrag2"></span>
                    </div>
                    <div class="img-box" @click="handleModalPicTap(index)">
                      <img :src="item.img" alt="" v-if="item.img" />
                      <div class="upload-box" v-else>
                        <Icon type="ios-camera-outline" size="36" />
                      </div>
                      <div class="delect-btn" @click.stop="handleBindDelete(item, index)">
                        <Icon type="md-close-circle" size="26" />
                      </div>
                    </div>
                    <div class="info">
                      <div class="info-item">
                        <span>广告标题：</span>
                        <div class="input-box">
                          <Input v-model="item.title" placeholder="请填写名称" />
                        </div>
                      </div>
                      <div class="info-item">
                        <span>链接地址：</span>
                        <div class="input-box" @click="handleLink(index)">
                          <Input v-model="item.link" icon="ios-arrow-forward" readonly placeholder="选择链接" />
                        </div>
                      </div>
                    </div>
                  </div>
                </draggable>
                <div>
                  <Modal
                    v-model="modalPic"
                    width="950px"
                    scrollable
                    footer-hide
                    closable
                    title="上传图片"
                    :mask-closable="false"
                    :z-index="999">
                    <UploadPictures :isChoice="isChoice" @getPic="getPic" :gridBtn="gridBtn" :gridPic="gridPic" v-if="modalPic"></UploadPictures>
                  </Modal>
                </div>
              </div>
              <template>
                <div class="add-btn">
                  <Button type="primary" ghost style="width: 100px; height: 35px; background-color: #1890ff; color: #ffffff" @click="handleAddBox">
                    添加图片
                  </Button>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <Spin size="large" fix v-if="spinShow"></Spin>
    </Card>
    <LinkAddress ref="linkaddres" @linkUrl="linkUrl"></LinkAddress>
  </div>
</template>

<script>
import Setting from '@/config/setting'
import {
  getGroupAllApi,
  getGroupDataListApi,
  groupSaveApi,
  getOpenAdvSaveApi,
  setAgreementApi
} from '@/api/system'

import draggable from 'vuedraggable'
import mixins from '@/mixins'

export default {
  name: 'visualization',
  mixins: [mixins],
  components: {
    draggable
  },
  data () {
    return {
      spinShow: false,
      sortName: null,
      // 判断的隐私协议
      a: 0,
      guide: 0,
      // 数据组
      groupAll: [],
      // 默认标签
      name: 'routine_home_banner',
      url: '',
      BaseURL: Setting.apiBaseURL.replace(/adminapi/, ''),

      // 数据组
      tabList: [],
      sginList: [],

      // 分页ID
      pageId: 0,
      // 当前下标
      activeIndexs: 0,

      // 轮播图配置
      swiperOption: {
        // 显示分页
        pagination: {
          el: '.swiper-pagination'
        },
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // 自动轮播
        autoplay: {
          delay: 2000,
          // 当用户滑动图片后继续自动轮播
          disableOnInteraction: false
        },
        // 开启循环模式
        loop: false
      },

      // 上传组件配置
      isChoice: '单选',
      modalPic: false,

      // 最后数据
      lastObj: {
        add_time: '',
        comment: '',
        gid: '',
        id: '',
        img: '',
        link: '',
        sort: '',
        status: 1
      }
    }
  },
  mounted () {
    this.getGroupAllRequest()
    this.getGroupInfoRequest()
    this.url = this.BaseURL + 'pages/columnGoods/HotNewGoods/index?type=1'
  },
  methods: {
    // 获取数据组的列表
    getGroupAllRequest () {
      this.spinShow = true
      getGroupAllApi().then(res => {
        this.groupAll = res.data
        this.sortName = res.data[0].config_name
        this.pageId = res.data[0].id
        this.spinShow = false
      }).catch(err => {
        this.$Message.error(err.msg)
      })
    },
    getGroupInfoRequest () {
      getGroupDataListApi({ config_name: this.name }, 'setting/group_data').then(res => {
        this.tabList = res.data
        if (this.name == 'admin_login_slide') {
          this.tabList.list.forEach((item, index, array) => {
            if (typeof item.slide != 'string' && item.slide != 'undefined') {
              item.slide = item.slide[0]
            }
          })
        } else if (this.name == 'sign_day_num') {
          this.cmsList = res.data.list
        } else if (this.name == 'user_recharge_quota') {
          this.sginList = res.data
        } else {
          this.tabList.list.forEach((item, index, array) => {
            if (typeof item.img != 'string' && item.img != 'undefined') {
              item.img = item.img[0]
            }
          })
        }
      }).catch(err => {
        this.loading = false
        this.$Message.error(err.msg)
      })
    },
    linkUrl (e) {
      this.tabList.list[this.activeIndexs].link = e
    },
    // 处理数据组添加
    onsubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          setAgreementApi(this.formValidate).then(async (res) => {
            this.$Message.success(res.msg)
          }).catch((res) => {
            this.$Message.error(res.msg)
          })
        } else {
          return false
        }
      })
    },
    handleSave () {
      if (this.a == 1) {
        this.onsubmit('formValidate')
      } else if (this.guide == 2) {
        this.formItem.value = this.tabList.list
        getOpenAdvSaveApi(this.formItem).then((res) => {
          this.$Message.success(res.msg)
        })
      } else {
        this.loadingExist = true
        groupSaveApi({
          gid: this.pageId,
          config_name: this.name,
          data: this.tabList.list
        }).then((res) => {
          this.loadingExist = false
          this.$Message.success(res.msg)
        }).catch((err) => {
          this.loadingExist = false
          this.$Message.error(err.msg)
        })
      }
    },
    // 上传组件事件
    getPic (pc) {
      this.$nextTick(() => {
        if (this.name == 'admin_login_slide') {
          this.tabList.list[this.activeIndex].slide = pc.att_dir
        } else {
          console.log(this.activeIndex)
          console.log(this.tabList.list)
          this.tabList.list[this.activeIndex].img = pc.att_dir
        }
        this.modalPic = false
      })
    },
    // 打开上传素材组件
    handleModalPicTap (index) {
      this.activeIndex = index
      this.modalPic = true
    },
    // 动态表单删除事件
    handleBindDelete (item, index) {
      console.log(index)
      this.tabList.list.splice(index, 1)
    },
    // 添加图片
    handleAddBox () {
      if (this.tabList.list.length == 0) {
        this.tabList.list.push(this.lastObj)
        this.lastObj = {
          add_time: '',
          comment: '',
          gid: '',
          id: '',
          img: '',
          link: '',
          sort: '',
          status: 1
        }
      } else {
        if (this.tabList.list.length == 5) {
          this.$Message.warning('最多添加五张呦')
        } else {
          let obj = JSON.parse(JSON.stringify(this.lastObj))
          this.tabList.list.push(obj)
        }
      }
    },
    // 添加连接地址
    handleLink (index) {
      this.activeIndexs = index
      this.$refs.linkaddres.modals = true
    }
  }
}
</script>

<style scoped lang="scss">
.left-wrapper {
  min-width: 213px;
  background: #fff;
  border-right: 1px solid #f2f2f2;
}

.box-wrapper {
  display: flex;
  flex-wrap: nowrap;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
}

.moddile {
  position: absolute;
  width: 310px;
  height: 550px;
  top: 0px;
  opacity: 0;
  left: 0px;
  border-radius: 4px;
}

.moddile_box {
  position: absolute;
  top: 0px;
  width: 310px;
  height: 115px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  left: 0px;
  background-color: #f5f5f5;
}

.moddile_goods {
  position: absolute;
  top: 12px;
  width: 284px;
  height: 124px;
  /* border-top-left-radius: 10px; */
  /* border-top-right-radius: 10px; */
  border-radius: 5px;
  left: 13px;
  line-height: 99px;
  text-align: center;
  background-color: #f5f5f5;
}

.title {
  padding: 0 0 13px 0;
  font-weight: bold;
  font-size: 15px;
  border-left: 2px solid #1890FF;
  height: 23px;
  padding-left: 10px;
}

.title-text {
  padding: 0 0 0px 16px;
  color: #999;
  font-size: 12px;
  margin-top: 10px;
}

.item {
  margin-right: 15px;
  border: 1px dashed #dbdbdb;
  padding-bottom: 10px;
  padding-right: 15px;
  padding-top: 20px;
}

.items {
  margin-right: 15px;
  border: 1px dashed #dbdbdb;
  padding-bottom: 10px;
  padding-top: 15px;
  position: relative;
  display: flex;
  margin-top: 20px;

  .move-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 80px;
    cursor: move;
    color: #D8D8D8;
  }

  .img-box {
    position: relative;
    width: 80px;
    height: 80px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .info {
    flex: 1;
    margin-left: 22px;

    .info-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      span {
        // width 40px
        font-size: 13px;

        .input-box {
          flex: 1;
        }
      }
    }
  }

  .delect-btn {
    position: absolute;
    right: -12px;
    top: -12px;
    color: #999999;

    .iconfont {
      font-size: 28px;
      color: #999;
    }
  }
}

.upload-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #ccc;
}

.content {
  // width 510px;
  margin-left: 10px;
  .right-box {
    margin-left: 40px;
  }
}
.contents {
  width: 150px;
  margin-left: 10px;

  .right-box {
    margin-left: 40px;
  }

  .title-text {
    width: 500px;
  }
}

.hot_imgs {
  margin-bottom: 20px;

  .title {
    font-size: 14px;
  }

  .list-box {
    .item {
      position: relative;
      display: flex;
      margin-top: 20px;

      .move-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 80px;
        cursor: move;
        color: #D8D8D8;
      }

      .img-box {
        position: relative;
        width: 80px;
        height: 80px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .info {
        flex: 1;
        margin-left: 22px;

        .info-item {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          span {
            // width 40px
            font-size: 13px;
          }

          .input-box {
            flex: 1;
          }
        }
      }

      .delect-btn {
        position: absolute;
        right: -12px;
        top: -12px;
        color: #999999;

        .iconfont {
          font-size: 28px;
          color: #999;
        }
      }
    }
  }

  .add-btn {
    margin-top: 20px;
  }
}

.iframe-box {
  width: 310px;
  height: 100%;
  border-radius: 10px;
  // margin: 30px 15px 0px 15px
  background: rgba(0, 0, 0, 0);
  border: 1px solid #EEEEEE;
  opacity: 1;

  img {
    width: 100%;
    height: 100%;
  }
}

/deep/ .ivu-menu-vertical .ivu-menu-item-group-title {
  display: none;
}
</style>
